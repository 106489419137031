import { GetServerSideProps, NextPage } from 'next'
import { MetaHeader } from 'src/components/organisms/MetaHeader'
import { PageSignIn } from 'src/components/pages'
import { nocoSDK } from 'src/fixtures/modules/initialize'
import { getSubdomainFromHost } from 'src/fixtures/utils/url'

type InitialProps = {}

type Props = { subdomain?: string } & InitialProps

const Index: NextPage<Props> = ({ subdomain }: Props) => {
  return (
    <>
      <MetaHeader noIndex />
      <PageSignIn subdomain={subdomain} />
    </>
  )
}

export default Index

export const getServerSideProps: GetServerSideProps = async ctx => {
  nocoSDK.initalize()
  const meApi = nocoSDK.client?.userService.meApi

  const confirmToken = ctx.req.cookies.token
  if (confirmToken) {
    // @MEMO: token が期限切れの可能性があるのでチェックする。
    const res = await meApi
      ?.ApiFactory(confirmToken)
      .userV1MeGet()
      .catch(() => {})
    if (res?.data) return { redirect: { destination: `/`, permanent: false } }
  }

  const host = ctx.req.headers.host
  const subdomain = getSubdomainFromHost(host)
  return { props: { subdomain: subdomain || '' } }
}
