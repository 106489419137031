import Head from 'next/head'
import React from 'react'

export interface MetaHeaderProps {
  title?: string | null
  ogUrl?: string | null
  ogType?: string | null
  ogTitle?: string | null
  ogDescription?: string | null
  ogImage?: string | null
  noIndex?: boolean
}

export const MetaHeader = ({
  title,
  ogUrl,
  ogType,
  ogTitle,
  ogImage,
  ogDescription,
  noIndex = false
}: MetaHeaderProps) => {
  return (
    <Head>
      <title key="title">{title || 'ノコセル'}</title>
      {ogUrl && <meta key="og-url" property="og:url" content={ogUrl} />}
      {ogType && <meta key="og-type" property="og:type" content={ogType} />}
      {ogTitle && <meta key="og-title" property="og:title" content={ogTitle} />}
      {ogDescription && <meta key="og-description" property="og:description" content={ogDescription} />}
      {ogImage && <meta key="og-image" property="og:image" content={ogImage} />}
      {noIndex && <meta name="robots" content="noindex" />}
      {ogImage && <meta key="twitter-card" name="twitter:card" content={'summary_large_image'} />}
      {ogImage && <meta key="fb:app_id" name="fb:app_id" content={'717376999545089'} />}
    </Head>
  )
}
